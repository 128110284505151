exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-astro-astro-dynamic-image-prop-js": () => import("./../../../src/pages/blog/astro/astro-dynamic-image-prop.js" /* webpackChunkName: "component---src-pages-blog-astro-astro-dynamic-image-prop-js" */),
  "component---src-pages-blog-astro-astro-fix-waiting-for-the-image-integration-js": () => import("./../../../src/pages/blog/astro/astro-fix-waiting-for-the-image-integration.js" /* webpackChunkName: "component---src-pages-blog-astro-astro-fix-waiting-for-the-image-integration-js" */),
  "component---src-pages-blog-astro-astro-mdx-images-wrapped-inside-paragraphs-js": () => import("./../../../src/pages/blog/astro/astro-mdx-images-wrapped-inside-paragraphs.js" /* webpackChunkName: "component---src-pages-blog-astro-astro-mdx-images-wrapped-inside-paragraphs-js" */),
  "component---src-pages-blog-astro-astro-statistics-js": () => import("./../../../src/pages/blog/astro/astro-statistics.js" /* webpackChunkName: "component---src-pages-blog-astro-astro-statistics-js" */),
  "component---src-pages-blog-astro-astro-typeof-import-module-scss-error-js": () => import("./../../../src/pages/blog/astro/astro-typeof-import-module-scss-error.js" /* webpackChunkName: "component---src-pages-blog-astro-astro-typeof-import-module-scss-error-js" */),
  "component---src-pages-blog-astro-how-to-use-scss-modules-in-astro-js": () => import("./../../../src/pages/blog/astro/how-to-use-scss-modules-in-astro.js" /* webpackChunkName: "component---src-pages-blog-astro-how-to-use-scss-modules-in-astro-js" */),
  "component---src-pages-blog-astro-vs-gatsby-feature-comparison-js": () => import("./../../../src/pages/blog/astro-vs-gatsby-feature-comparison.js" /* webpackChunkName: "component---src-pages-blog-astro-vs-gatsby-feature-comparison-js" */),
  "component---src-pages-blog-biggest-companies-using-gatsby-js": () => import("./../../../src/pages/blog/biggest-companies-using-gatsby.js" /* webpackChunkName: "component---src-pages-blog-biggest-companies-using-gatsby-js" */),
  "component---src-pages-blog-can-html-summary-by-heading-element-js": () => import("./../../../src/pages/blog/can-html-summary-by-heading-element.js" /* webpackChunkName: "component---src-pages-blog-can-html-summary-by-heading-element-js" */),
  "component---src-pages-blog-css-img-all-deprecated-api-js": () => import("./../../../src/pages/blog/css-img-all-deprecated-api.js" /* webpackChunkName: "component---src-pages-blog-css-img-all-deprecated-api-js" */),
  "component---src-pages-blog-enabling-node-18-in-vercel-for-gatsby-5-js": () => import("./../../../src/pages/blog/enabling-node-18-in-vercel-for-gatsby-5.js" /* webpackChunkName: "component---src-pages-blog-enabling-node-18-in-vercel-for-gatsby-5-js" */),
  "component---src-pages-blog-fix-gatsby-netlify-error-code-instead-of-404-page-js": () => import("./../../../src/pages/blog/fix-gatsby-netlify-error-code-instead-of-404-page.js" /* webpackChunkName: "component---src-pages-blog-fix-gatsby-netlify-error-code-instead-of-404-page-js" */),
  "component---src-pages-blog-fixing-gatsby-hydration-flicker-issue-js": () => import("./../../../src/pages/blog/fixing-gatsby-hydration-flicker-issue.js" /* webpackChunkName: "component---src-pages-blog-fixing-gatsby-hydration-flicker-issue-js" */),
  "component---src-pages-blog-fixing-rendertopipeablestream-error-gatsby-js": () => import("./../../../src/pages/blog/fixing-rendertopipeablestream-error-gatsby.js" /* webpackChunkName: "component---src-pages-blog-fixing-rendertopipeablestream-error-gatsby-js" */),
  "component---src-pages-blog-gatsby-dynamic-body-class-js": () => import("./../../../src/pages/blog/gatsby-dynamic-body-class.js" /* webpackChunkName: "component---src-pages-blog-gatsby-dynamic-body-class-js" */),
  "component---src-pages-blog-gatsby-overwrite-default-sitemap-file-js": () => import("./../../../src/pages/blog/gatsby-overwrite-default-sitemap-file.js" /* webpackChunkName: "component---src-pages-blog-gatsby-overwrite-default-sitemap-file-js" */),
  "component---src-pages-blog-how-to-add-time-to-read-on-contentful-gatsby-js": () => import("./../../../src/pages/blog/how-to-add-time-to-read-on-contentful-gatsby.js" /* webpackChunkName: "component---src-pages-blog-how-to-add-time-to-read-on-contentful-gatsby-js" */),
  "component---src-pages-blog-how-to-disable-react-in-next-js": () => import("./../../../src/pages/blog/how-to-disable-react-in-next.js" /* webpackChunkName: "component---src-pages-blog-how-to-disable-react-in-next-js" */),
  "component---src-pages-blog-how-to-enable-node-18-for-gatsby-netlify-js": () => import("./../../../src/pages/blog/how-to-enable-node-18-for-gatsby-netlify.js" /* webpackChunkName: "component---src-pages-blog-how-to-enable-node-18-for-gatsby-netlify-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-prismic-preview-stuck-loading-js": () => import("./../../../src/pages/blog/prismic-preview-stuck-loading.js" /* webpackChunkName: "component---src-pages-blog-prismic-preview-stuck-loading-js" */),
  "component---src-pages-blog-react-hydration-error-explained-js": () => import("./../../../src/pages/blog/react-hydration-error-explained.js" /* webpackChunkName: "component---src-pages-blog-react-hydration-error-explained-js" */),
  "component---src-pages-blog-reasons-to-use-gatsby-js": () => import("./../../../src/pages/blog/reasons-to-use-gatsby.js" /* webpackChunkName: "component---src-pages-blog-reasons-to-use-gatsby-js" */),
  "component---src-pages-blog-remove-dots-from-unordered-lists-js": () => import("./../../../src/pages/blog/remove-dots-from-unordered-lists.js" /* webpackChunkName: "component---src-pages-blog-remove-dots-from-unordered-lists-js" */),
  "component---src-pages-blog-seo-comparison-of-gatsby-vs-next-vs-astro-js": () => import("./../../../src/pages/blog/seo-comparison-of-gatsby-vs-next-vs-astro.js" /* webpackChunkName: "component---src-pages-blog-seo-comparison-of-gatsby-vs-next-vs-astro-js" */),
  "component---src-pages-blog-shopify-payment-required-api-error-js": () => import("./../../../src/pages/blog/shopify-payment-required-api-error.js" /* webpackChunkName: "component---src-pages-blog-shopify-payment-required-api-error-js" */),
  "component---src-pages-case-studies-buddy-health-care-development-js": () => import("./../../../src/pages/case-studies/buddy-health-care-development.js" /* webpackChunkName: "component---src-pages-case-studies-buddy-health-care-development-js" */),
  "component---src-pages-case-studies-building-winding-road-website-js": () => import("./../../../src/pages/case-studies/building-winding-road-website.js" /* webpackChunkName: "component---src-pages-case-studies-building-winding-road-website-js" */),
  "component---src-pages-case-studies-equipment-finance-canada-js": () => import("./../../../src/pages/case-studies/equipment-finance-canada.js" /* webpackChunkName: "component---src-pages-case-studies-equipment-finance-canada-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-case-studies-jamstack-development-for-worked-js": () => import("./../../../src/pages/case-studies/jamstack-development-for-worked.js" /* webpackChunkName: "component---src-pages-case-studies-jamstack-development-for-worked-js" */),
  "component---src-pages-case-studies-jamstack-ecommerce-for-spotless-js": () => import("./../../../src/pages/case-studies/jamstack-ecommerce-for-spotless.js" /* webpackChunkName: "component---src-pages-case-studies-jamstack-ecommerce-for-spotless-js" */),
  "component---src-pages-case-studies-perfect-seo-with-astro-js": () => import("./../../../src/pages/case-studies/perfect-seo-with-astro.js" /* webpackChunkName: "component---src-pages-case-studies-perfect-seo-with-astro-js" */),
  "component---src-pages-case-studies-scaling-with-wa-js": () => import("./../../../src/pages/case-studies/scaling-with-wa.js" /* webpackChunkName: "component---src-pages-case-studies-scaling-with-wa-js" */),
  "component---src-pages-case-studies-ticketbro-animations-development-js": () => import("./../../../src/pages/case-studies/ticketbro-animations-development.js" /* webpackChunkName: "component---src-pages-case-studies-ticketbro-animations-development-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jere-huttunen-astro-freelancer-js": () => import("./../../../src/pages/jere-huttunen/astro-freelancer.js" /* webpackChunkName: "component---src-pages-jere-huttunen-astro-freelancer-js" */),
  "component---src-pages-jere-huttunen-gatsby-freelancer-js": () => import("./../../../src/pages/jere-huttunen/gatsby-freelancer.js" /* webpackChunkName: "component---src-pages-jere-huttunen-gatsby-freelancer-js" */),
  "component---src-pages-jere-huttunen-index-js": () => import("./../../../src/pages/jere-huttunen/index.js" /* webpackChunkName: "component---src-pages-jere-huttunen-index-js" */),
  "component---src-pages-jere-huttunen-next-freelancer-js": () => import("./../../../src/pages/jere-huttunen/next-freelancer.js" /* webpackChunkName: "component---src-pages-jere-huttunen-next-freelancer-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quick-seo-tips-can-aside-be-inside-main-js": () => import("./../../../src/pages/quick-seo-tips/can-aside-be-inside-main.js" /* webpackChunkName: "component---src-pages-quick-seo-tips-can-aside-be-inside-main-js" */),
  "component---src-pages-quick-seo-tips-dealing-with-empty-datemodified-schema-js": () => import("./../../../src/pages/quick-seo-tips/dealing-with-empty-datemodified-schema.js" /* webpackChunkName: "component---src-pages-quick-seo-tips-dealing-with-empty-datemodified-schema-js" */),
  "component---src-pages-quick-seo-tips-does-font-loading-order-matter-js": () => import("./../../../src/pages/quick-seo-tips/does-font-loading-order-matter.js" /* webpackChunkName: "component---src-pages-quick-seo-tips-does-font-loading-order-matter-js" */),
  "component---src-pages-quick-seo-tips-how-to-load-image-only-on-desktop-js": () => import("./../../../src/pages/quick-seo-tips/how-to-load-image-only-on-desktop.js" /* webpackChunkName: "component---src-pages-quick-seo-tips-how-to-load-image-only-on-desktop-js" */),
  "component---src-pages-quick-seo-tips-index-js": () => import("./../../../src/pages/quick-seo-tips/index.js" /* webpackChunkName: "component---src-pages-quick-seo-tips-index-js" */),
  "component---src-pages-quick-seo-tips-proper-semantic-header-code-sample-js": () => import("./../../../src/pages/quick-seo-tips/proper-semantic-header-code-sample.js" /* webpackChunkName: "component---src-pages-quick-seo-tips-proper-semantic-header-code-sample-js" */),
  "component---src-pages-quick-seo-tips-tip-for-fixing-heading-elements-order-js": () => import("./../../../src/pages/quick-seo-tips/tip-for-fixing-heading-elements-order.js" /* webpackChunkName: "component---src-pages-quick-seo-tips-tip-for-fixing-heading-elements-order-js" */),
  "component---src-pages-quick-seo-tips-using-av-1-video-format-as-source-in-video-js": () => import("./../../../src/pages/quick-seo-tips/using-av1-video-format-as-source-in-video.js" /* webpackChunkName: "component---src-pages-quick-seo-tips-using-av-1-video-format-as-source-in-video-js" */),
  "component---src-pages-quick-seo-tips-using-fetchpriority-with-images-js": () => import("./../../../src/pages/quick-seo-tips/using-fetchpriority-with-images.js" /* webpackChunkName: "component---src-pages-quick-seo-tips-using-fetchpriority-with-images-js" */),
  "component---src-pages-services-astro-development-js": () => import("./../../../src/pages/services/astro-development.js" /* webpackChunkName: "component---src-pages-services-astro-development-js" */),
  "component---src-pages-services-contentful-development-js": () => import("./../../../src/pages/services/contentful-development.js" /* webpackChunkName: "component---src-pages-services-contentful-development-js" */),
  "component---src-pages-services-design-to-code-js": () => import("./../../../src/pages/services/design-to-code.js" /* webpackChunkName: "component---src-pages-services-design-to-code-js" */),
  "component---src-pages-services-gatsby-development-js": () => import("./../../../src/pages/services/gatsby-development.js" /* webpackChunkName: "component---src-pages-services-gatsby-development-js" */),
  "component---src-pages-services-hubspot-theme-development-js": () => import("./../../../src/pages/services/hubspot-theme-development.js" /* webpackChunkName: "component---src-pages-services-hubspot-theme-development-js" */),
  "component---src-pages-services-next-development-js": () => import("./../../../src/pages/services/next-development.js" /* webpackChunkName: "component---src-pages-services-next-development-js" */),
  "component---src-pages-services-page-speed-optimization-js": () => import("./../../../src/pages/services/page-speed-optimization.js" /* webpackChunkName: "component---src-pages-services-page-speed-optimization-js" */),
  "component---src-pages-services-storyblok-development-js": () => import("./../../../src/pages/services/storyblok-development.js" /* webpackChunkName: "component---src-pages-services-storyblok-development-js" */),
  "component---src-pages-services-technical-seo-js": () => import("./../../../src/pages/services/technical-seo.js" /* webpackChunkName: "component---src-pages-services-technical-seo-js" */),
  "component---src-pages-tools-html-webp-picture-generator-js": () => import("./../../../src/pages/tools/html-webp-picture-generator.js" /* webpackChunkName: "component---src-pages-tools-html-webp-picture-generator-js" */),
  "component---src-pages-tools-index-js": () => import("./../../../src/pages/tools/index.js" /* webpackChunkName: "component---src-pages-tools-index-js" */),
  "component---src-pages-tools-svg-css-background-generator-js": () => import("./../../../src/pages/tools/svg-css-background-generator.js" /* webpackChunkName: "component---src-pages-tools-svg-css-background-generator-js" */)
}

